import React from "react";
import { graphql } from "gatsby";

import DefaultLayout, { lightboxIndexGlobal } from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import TwoImageGrid from "../components/TwoImageGrid";
import ThreeImageGrid from "../components/ThreeImageGrid";
import FourImageGrid from "../components/FourImageGrid";
import CenteredTextBlock from "../components/CenteredTextBlock";
import FWGrid from "../components/Layout/Grids/image/FWGrid";
import GreenLead from "../components/GreenLead";

import { lightboxOpenGlobal, lightboxContentGlobal } from "../layouts/default";
import ImageCarousel from "../components/ImageCarousel";

interface ProjekteProps {
  data: {
    cms: {
      inhalt: {
        title: string;
        greenLead: string;
        projekte: [
          {
            text: string;
            bilder: [{ url: string; title: string }];
          }
        ];
      };
    };
  };
}

export const ProjekteQuery = graphql`
query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      lokalisierung: entry(type: "metaDatenLokalisierung", siteId: [$siteId]) {
        ... on CMS_metaDatenLokalisierung_Entry {
          alleProjekte
        }
      }

      inhalt: entry(uri: [$uri], siteId: [$siteId]) {
        siteId
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_projekte_Entry {
          greenLead
        }
        children {
          title
          ... on CMS_projekteItem_Entry {
            normalText
            videosImagesProjekteItem {
              ... on CMS_image2_Entry {
                bild {
                  url
                  title
                }
              }
              ... on CMS_youtubeVideo2_Entry {
                videoUrl
              }
            }
          }
        }
        ... on CMS_projekte_Entry {
          metaBeschreibung
          greenLead
        }
      }
    }
  }
`;

const AlleProjekte: React.FC<ProjekteProps> = ({ data }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = lightboxOpenGlobal();
  const [lightboxContent, setLightboxContent] = lightboxContentGlobal();
  const [lightboxIndex, setLightboxIndex] = lightboxIndexGlobal();

  const getContent = (obj, index) => {
    if (obj.videosImagesProjekteItem.length === 1) {
      return (
        <div key={index}>
          <CenteredTextBlock
            lessBottomSpacing
            inhalt={"<h6>" + obj.title + "</h6>" + obj.normalText}
          />
          {obj.videosImagesProjekteItem[0].bild ? (
            <FWGrid
              image={obj.videosImagesProjekteItem[0].bild[0]}
              lightboxInhalt={obj.videosImagesProjekteItem}
              setLightboxIsOpen={setLightboxIsOpen}
              setLightboxContent={setLightboxContent}
            />
          ) : obj.videosImagesProjekteItem[0].videoUrl ? (
            <FWGrid
              video={obj.videosImagesProjekteItem[0].videoUrl}
              lightboxInhalt={obj.videosImagesProjekteItem}
              setLightboxIsOpen={setLightboxIsOpen}
              setLightboxContent={setLightboxContent}
            />
          ) : (
            ""
          )}
        </div>
      );
    } else if (obj.videosImagesProjekteItem.length >= 2) {
      return (
        <div key={index}>
          <CenteredTextBlock
            lessBottomSpacing
            inhalt={"<h6>" + obj.title + "</h6>" + obj.normalText}
          />
          <ImageCarousel
            inhalt={obj.videosImagesProjekteItem}
            setLightboxIsOpen={setLightboxIsOpen}
            setLightboxContent={setLightboxContent}
            setLightboxIndex={setLightboxIndex}
          />
        </div>
      );
    } else if (obj.normalText) {
      <CenteredTextBlock
        lessBottomSpacing
        inhalt={"<h6>" + obj.title + "</h6>" + obj.normalText}
        key={index}
      />;
    }
  };
  return (
    <DefaultLayout
      siteId={data.cms.inhalt.siteId}
      title={data.cms.lokalisierung.alleProjekte}
      //links={data.cms.inhalt.localized}
      beschreibung={
        data.cms.inhalt.metaBeschreibung
          ? data.cms.inhalt.metaBeschreibung
          : false
      }
    >
      <HeaderTitle inhalt={data.cms.lokalisierung.alleProjekte} />
      <GreenLead inhalt={data.cms.inhalt.greenLead} />
      {data.cms.inhalt.children.map((obj, index) => getContent(obj, index))}
    </DefaultLayout>
  );
};

export default AlleProjekte;
